<template>
  <el-tabs v-model="activeName">
    <el-tab-pane
      label="Quiz Weekly Trendline"
      :name="'weeklyTrendline_' + this.recordType + '_' + this.reportId"
      :lazy="false"
    >
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { getData } from "../../service/api";
import { Chart, registerables } from "chart.js";

export default {
  props: {
    isForAdmin: Boolean,
    recordType: String,
    reportId: String,
    studentId: String,
  },
  data() {
    return {
      controllerUrl: "/weeklyReport",
      query: {
        recordType: null,
        studentId: null,
      },
      styleConfig: {
        line: {
          total: {
            font: "bold 12px Arial",
            borderColor: "#233c64",
            bgColor: "#233c64",
            pointColor: "#233c64",
          },
        },
        medianLine: {
          total: {
            font: "bold 12px Arial",
            borderColor: "#55bae7",
            bgColor: "#55bae7",
            pointColor: "#55bae7",
          },
        },
      },
      activeName: null,
      chartList: [],
      weeklyRecordListInAvgAndMedianList: [],
      studentWeeklyRecordList: [],
      medianWeeklyRecordList: [],
      studentWeeklyRecordDic: {},
      medianWeeklyRecordDic: {},
    };
  },
  watch: {
    recordType(newValue) {
      console.log("watch.recordType", newValue);
      if (newValue) {
        if (newValue !== this.query.recordType) {
          this.initAndLoadData(newValue, this.studentId);
        }
      }
    },
    reportId(newValue) {
      console.log("watch.reportId", newValue);
      if (newValue) {
        if (newValue !== this.query.reportId) {
          this.initAndLoadData(this.recordType, newValue, this.studentId);
        }
      }
    },
    studentId(newValue) {
      console.log("watch.studentId", newValue);
      if (newValue) {
        if (newValue !== this.query.studentId) {
          this.initAndLoadData(this.recordType, this.reportId, newValue);
        }
      }
    },
  },
  created() {
    Chart.register(...registerables);
    this.activeName = `weeklyTrendline_${this.recordType}_${this.reportId}`;
    if (this.isForAdmin) {
      this.initAndLoadData(this.recordType, this.reportId, this.studentId);
    } else {
      let studentId = this.$user.getUsername();
      if (studentId) {
        this.initAndLoadData(this.recordType, this.reportId, studentId);
      }
    }
  },
  mounted() {},
  methods: {
    initAndLoadData(recordType, reportId, studentId) {
      this.query.recordType = recordType;
      this.query.reportId = reportId;
      this.query.studentId = studentId;
      if (!this.query.studentId) return;
      this.loadData();
    },
    async loadData() {
      let url = `${this.controllerUrl}/getStudentWeeklyReportInRank/${this.recordType}/${this.query.reportId}/${this.query.studentId}`;
      let res = await getData(url);
      if (res.result && res.code === "200") {
        const weekNameList = [
          "w1",
          "w2",
          "w3",
          "w4",
          "w5",
          "w6",
          "w7",
          "w8",
          "w9",
          "w10",
        ];
        let weeklyRecord = res.result;
        let labelList = [];
        for (let i = 0; i < weekNameList.length; i++) {
          labelList.push(this.$formatter.toUpperFirstChar(weekNameList[i]));
        }
        for (let recordType in this.styleConfig.line) {
          let list1 = [];
          let list2 = [];

          for (let i = 0; i < weekNameList.length; i++) {
            let weekName = weekNameList[i];
            list1.push(weeklyRecord[`${weekName}Rank`]);
          }
          this.studentWeeklyRecordDic[recordType] = list1;

          for (let i = 0; i < weekNameList.length; i++) {
            list2.push(50);
          }
          this.medianWeeklyRecordDic[recordType] = list2;
        }
        console.log(
          "loadData",
          this.weeklyRecord,
          labelList,
          this.studentWeeklyRecordDic,
          this.medianWeeklyRecordDic
        );
        this.chartList = [];
        for (let recordType in this.styleConfig.line) {
          let styleObj = this.styleConfig.line[recordType];
          let medianStyleObj = this.styleConfig.medianLine[recordType];
          let parentNode = document.getElementById(
            `pane-weeklyTrendline_${this.recordType}_${this.reportId}`
          );
          parentNode.innerHTML = "";
          this.renderChart(
            parentNode,
            recordType,
            labelList,
            this.studentWeeklyRecordDic[recordType],
            this.medianWeeklyRecordDic[recordType],
            styleObj,
            medianStyleObj
          );
        }
      }
    },
    renderChart(
      parentNode,
      recordType,
      labelList,
      dataList,
      medianDataList,
      styleObj,
      medianStyleObj
    ) {
      console.log("renderChart", dataList, medianDataList, styleObj, medianStyleObj);

      let ctx = document.createElement("canvas");
      let div = document.createElement("div");
      div.appendChild(ctx);
      parentNode.appendChild(div);

      let chart = new Chart(ctx, {
        type: "line",
        options: {
          plugins: {
            title: {
              display: true,
              position: "top",
              text: `Weekly Percentile Trendline of My ${this.$formatter.toUpperFirstChar(
                this.recordType
              )}`,
            },
          },
          scales: {
            y: {
              suggestedMin: 0,
              max: 100,
              ticks: {
                stepSize: 5,
              },
            },
          },
        },
        data: {
          labels: labelList,
          datasets: [
            {
              label: "My Percentile",
              data: dataList,
              borderColor: styleObj.borderColor,
              backgroundColor: styleObj.bgColor,
              pointBackgroundColor: styleObj.pointColor,
              pointBorderColor: styleObj.pointColor,
              pointHoverBackgroundColor: styleObj.pointColor,
              pointHoverBorderColor: styleObj.pointColor,
              tension: 0.1,
              borderWidth: 1,
            },
            {
              label: "Median Percentile",
              data: medianDataList,
              borderDash: [5, 5],
              borderColor: medianStyleObj.borderColor,
              backgroundColor: medianStyleObj.bgColor,
              pointBackgroundColor: medianStyleObj.pointColor,
              pointBorderColor: medianStyleObj.pointColor,
              pointHoverBackgroundColor: medianStyleObj.pointColor,
              pointHoverBorderColor: medianStyleObj.pointColor,
              tension: 0.1,
              borderWidth: 1,
            },
          ],
        },
      });
      chart["chartState"] = {
        othis: this,
        recordType: recordType,
        labelList: labelList,
        dataList: dataList,
      };

      this.chartList.push(chart);
    },
  },
};
</script>
