export const LessonOperationEnum = Object.freeze({
  Replay: "Replay(B)",
  Replay_Hot: "Replay(A)",
  Homework: "Homework",
  Answer: "Answer",
  ExtraFoundationExercise: "ExtraFoundationExercise",
  ExtraDevelopmentExercise: "ExtraDevelopmentExercise",
  ExtraEnrichmentExercise: "ExtraEnrichmentExercise",
});
export const LessonCategoryEnum = Object.freeze({
  Base: "Base",
  ExtraExercise: "ExtraExercise",
});
export const WeeklyRecordTypeEnum = Object.freeze({
  Homework: "homework",
  Quiz: "quiz",
});