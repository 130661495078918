<template>
  <el-table
    :data="filterRecordList()"
    border
    class="table"
    ref="multipleTable"
    :height="this.summaryTableHeight"
    highlight-current-row
    header-cell-class-name="table-header"
    @current-change="handleCurrentChange"
    :default-sort="{ prop: 'totalRank', order: 'descending' }"
    @sort-change="changeSort"
    :row-class-name="specifyTableRowClassName"
  >
    <el-table-column
      v-if="this.isForAdmin"
      prop="studentId"
      label="Student No."
      :width="this.$widthRatio * 140 + 'px'"
    >
      <template v-if="isForAdmin" #header>
        <el-input
          v-model="filterQuery.studentId"
          size="small"
          :clearable="true"
          placeholder="Student No."
        />
      </template>
    </el-table-column>
    <el-table-column v-if="this.isForAdmin" prop="studentName" label="Student Name">
      <template v-if="isForAdmin" #header>
        <el-input
          v-model="filterQuery.studentName"
          size="small"
          :clearable="true"
          placeholder="Student Name"
        />
      </template>
    </el-table-column>
    <el-table-column
      prop="w1"
      label="W1"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w1"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w2"
      label="W2"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w2"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w3"
      label="W3"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w3"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w4"
      label="W4"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w4"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w5"
      label="W5"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w5"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w6"
      label="W6"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w6"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w7"
      label="W7"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w7"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w8"
      label="W8"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w8"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w9"
      label="W9"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w9"
      :min-width="this.isForAdmin ? this.$widthRatio * 65 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      prop="w10"
      label="W10"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="w10"
      :min-width="this.isForAdmin ? this.$widthRatio * 70 + '' : 'auto'"
    ></el-table-column>
    <el-table-column
      v-if="!this.styleConfig.bar.total.disabled"
      prop="total"
      label="Total"
      :sortable="isForAdmin ? true : false"
      :sort-orders="['ascending', 'descending', null]"
      :sort-by="total"
      :min-width="this.isForAdmin ? this.$widthRatio * 80 + '' : 'auto'"
    >
    </el-table-column>
    <el-table-column
      prop="total"
      label="Points"
      :min-width="this.isForAdmin ? this.$widthRatio * 80 + '' : 'auto'"
    >
      <template v-slot="{ row }">
        <div
          v-if="row.total"
          :title="this.getPointsIssuedDescription(row)"
          :style="'color:' + this.getPointsIssuedColor(row) + ';'"
        >
          {{ Math.round(row.total * this.weeklyHomeworkPointsPerScore) }} Points
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="updatedTime"
      :formatter="$tableCellFormatter.formatDate"
      label="Updated Time"
      :min-width="this.isForAdmin ? this.$widthRatio * 100 + '' : 'auto'"
    ></el-table-column>
  </el-table>
  <table v-if="this.displayChart" class="dtable">
    <tr>
      <td>
        <el-tabs v-model="activeName">
          <el-tab-pane
            v-if="!this.styleConfig.bar.w1.disabled"
            label="W1"
            :name="'w1Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w2.disabled"
            label="W2"
            :name="'w2Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w3.disabled"
            label="W3"
            :name="'w3Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w4.disabled"
            label="W4"
            :name="'w4Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w5.disabled"
            label="W5"
            :name="'w5Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w6.disabled"
            label="W6"
            :name="'w6Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w7.disabled"
            label="W7"
            :name="'w7Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w8.disabled"
            label="W8"
            :name="'w8Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w9.disabled"
            label="W9"
            :name="'w9Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.w10.disabled"
            label="W10"
            :name="'w10Histogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
          <el-tab-pane
            v-if="!this.styleConfig.bar.total.disabled"
            label="Total"
            :name="'totalHistogram_' + this.recordType + '_' + this.reportId"
            :lazy="false"
          ></el-tab-pane>
        </el-tabs>
      </td>
      <td>
        <student-weekly-record-chart
          v-if="this.currentStudent"
          :isForAdmin="this.isForAdmin"
          :recordType="this.recordType"
          :reportId="this.reportId"
          :studentId="this.currentStudent.studentId"
        />
      </td>
    </tr>
  </table>
</template>
<script>
import { getData, postData } from "../../service/api";
import { Chart, registerables } from "chart.js";
import { WeeklyRecordTypeEnum } from "../../service/models/enums.js";
import StudentWeeklyRecordChart from "./StudentWeeklyRecordChart.vue";

export default {
  components: { StudentWeeklyRecordChart },
  props: {
    isForAdmin: Boolean,
    studentId: String,
    reportId: String,
    recordType: String,
  },
  data() {
    return {
      controllerUrl: "/weeklyReport",
      weeklyHomeworkPointsPerScore: 4,
      query: {
        studentId: null,
        reportId: null,
        recordType: null,
        pageIndex: 1,
        pageSize: 0x7fffffff,
        orderBy: "StudentName",
        orderDirection: "DESC",
      },
      filterQuery: {
        studentId: null,
        studentName: null,
      },
      styleConfig: {
        bar: {
          total: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(187, 219, 253, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w1: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(0,255,0, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w2: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(0,0,255, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w3: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(0,255,255, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w4: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(255,0,255, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w5: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(128,0,0, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w6: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(128,128,0, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w7: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(0,128,0, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w8: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(128,0,128, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w9: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(0,128,128, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
          w10: {
            percentile: false,
            font: "bold 12px Arial",
            bgColor: "rgba(0,0,128, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
            disabled: false,
          },
        },
        line: {
          average: {
            font: "bold 12px Arial",
            bgColor: "rgba(0, 0, 0, 0.2)",
            foreColor: "rgba(0, 0, 0, 0.2)",
            lineWidth: 3,
            textAlign: "start",
            drawText: false,
            isDashLine: false,
          },
          median: {
            font: "bold 12px Arial",
            bgColor: "rgba(255, 125, 0, 0.5)",
            foreColor: "rgba(255, 125, 0, 0.8)",
            lineWidth: 3,
            textAlign: "start",
            drawText: false,
            isDashLine: true,
            dashPattern: [9, 10],
          },
          percentile_85: {
            font: "bold 12px Arial",
            bgColor: "rgba(0, 0, 255, 0.5)",
            foreColor: "rgba(0, 0, 255, 0.8)",
            lineWidth: 3,
            textAlign: "start",
            drawText: false,
            isDashLine: true,
            dashPattern: [9, 10],
          },
          percentile_95: {
            font: "bold 12px Arial",
            bgColor: "rgba(0, 255, 0, 0.5)",
            foreColor: "rgba(0, 255, 0, 0.8)",
            lineWidth: 3,
            textAlign: "start",
            drawText: false,
            isDashLine: true,
            dashPattern: [9, 10],
          },
          grade: {
            font: "bold 12px Arial",
            bgColor: "rgba(255, 0, 0, 0.5)",
            bgColorForTransparency: "rgba(255, 0, 0, 0.0)",
            foreColor: "rgba(255, 0, 0, 0.8)",
            lineWidth: 3,
            textAlign: "start",
            drawText: false,
            isDashLine: false,
          },
          ranking: {
            font: "bold 12px Arial",
            foreColor: "#000000",
            foreColor1: "#000000",
            foreColor2: "#000000",
            foreColor3: "#000000",
            foreColor4: "#000000",
            foreColor5: "#000000",
            textAlign: "start",
          },
        },
      },
      weeklyReportSummary: {
        recordsDistribution: {},
        summarizedData: {},
        topStudents: {},
      },
      displayChart: false,
      summaryTableHeight: "auto",
      startAndStepsSettingList: [{}],
      activeName: null,
      currentReport: { records: {} },
      currentStudent: {},
      chartList: [],
      chartContext: {
        options: {
          indexAxis: "x",
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          responsive: true,
          plugins: {
            legend: {
              position: "top",
              labels: {
                filter: function () {
                  return true;
                },
              },
            },
            title: {
              display: false,
              position: "top",
              text: "TT Histogram",
            },
            tooltip: {
              callbacks: {
                title: function () {
                  return "";
                },
                label: function (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  let dataPoint = dataset.data[tooltipItem.dataIndex];
                  let label = tooltipItem.label;
                  let recordType = dataset.recordType;
                  let _this = dataset.othis;
                  let startAndStepsSetting = _this.startAndStepsSettingList.find(
                    (e) => e.column === recordType
                  );
                  if (startAndStepsSetting == null) return;
                  let steps = startAndStepsSetting.steps;
                  let end = parseInt(label);
                  let start = end - steps;
                  if (start < startAndStepsSetting.start) {
                    start = 0;
                  }
                  return `${start} - ${end}: ${dataPoint}`;
                },
              },
            },
          },
          layout: {
            padding: {
              top: 10,
              right: 160,
              bottom: 3,
              left: 3,
            },
          },
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          animation: {
            onComplete: function () {
              let chartInstance = this;
              let ctx = chartInstance.ctx;
              ctx.textAlign = "center";

              chartInstance.data.datasets.forEach(function (dataset, i) {
                let meta = chartInstance.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  let data = dataset.data[index];
                  let _this = dataset.othis;
                  let recordType = dataset.recordType;
                  let barStyle = _this.styleConfig.bar[recordType];
                  let percentile = barStyle.percentile;
                  let valueFieldName = percentile ? `${recordType}Rank` : recordType;
                  let labelAndValueObj = meta.controller.getLabelAndValue(index);
                  if (!_this.currentStudent) return;
                  let record = _this.currentStudent[valueFieldName];
                  let startAndStepsSetting = _this.startAndStepsSettingList.find(
                    (e) => e.column === recordType
                  );
                  if (startAndStepsSetting == null) return;
                  let steps = startAndStepsSetting.steps;
                  let end = parseInt(labelAndValueObj.label);
                  let start = end - steps;
                  if (start < startAndStepsSetting.start) {
                    start = steps;
                  }
                  if (data > 0 && record > start && record <= end) {
                    ctx.font = barStyle.font;
                    ctx.fillStyle = barStyle.foreColor;
                    // if (_this.query.studentId) {
                    //   ctx.fillText("You are here", bar.x / 2, bar.y + 5);
                    // } else {
                    //   ctx.fillText(
                    //     `${_this.currentStudent.studentName} is here`,
                    //     bar.x / 2,
                    //     bar.y + 5
                    //   );
                    // }
                  }
                });
              });
            },
          },
        },
        chartData: {
          w1: {
            labels: [],
            datasets: [
              {
                label: "W1",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w2: {
            labels: [],
            datasets: [
              {
                label: "W2",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w3: {
            labels: [],
            datasets: [
              {
                label: "W3",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w4: {
            labels: [],
            datasets: [
              {
                label: "W4",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w5: {
            labels: [],
            datasets: [
              {
                label: "W5",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w6: {
            labels: [],
            datasets: [
              {
                label: "W6",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w7: {
            labels: [],
            datasets: [
              {
                label: "W7",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w8: {
            labels: [],
            datasets: [
              {
                label: "W8",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w9: {
            labels: [],
            datasets: [
              {
                label: "W9",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
          w10: {
            labels: [],
            datasets: [
              {
                label: "W10",
                data: [],
                backgroundColor: "#FF8122",
              },
            ],
          },
          total: {
            labels: [],
            datasets: [
              {
                label: "Total Frequency",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
        },
      },
    };
  },
  watch: {
    reportId(newValue) {
      console.log("watch.reportId", newValue);
      if (newValue) {
        if (newValue !== this.query.reportId) {
          this.initAndLoadData(newValue, this.studentId, this.recordType);
        }
      }
    },
    recordType(newValue) {
      console.log("watch.recordType", newValue);
      if (newValue) {
        if (newValue !== this.query.recordType) {
          this.initAndLoadData(this.reportId, this.studentId, newValue);
        }
      }
    },
  },
  created() {
    Chart.register(...registerables);
    this.weeklyHomeworkPointsPerScore = this.$appSetting.getGenericValue(
      `WEEKLY_${this.recordType.toUpperCase()}_POINTS_PERSCORE`,
      4
    );
    this.activeName = `w1Histogram_${this.recordType}_${this.reportId}`;
    this.initializeLineColor("median", "TEST_REPORT_MEDIAN_LINE_COLOR");
    this.initializeLineColor("percentile_85", "TEST_REPORT_85_LINE_COLOR");
    this.initializeLineColor("percentile_95", "TEST_REPORT_95_LINE_COLOR");
    this.initializeLineColor("grade", "TEST_REPORT_STUDENT_LINE_COLOR");
    this.initAndLoadData(this.reportId, this.studentId, this.recordType);
  },
  mounted() {},
  methods: {
    initAndLoadData(reportId, studentId, recordType) {
      if (this.recordType === WeeklyRecordTypeEnum.Quiz) {
        this.summaryTableHeight = this.isForAdmin ? 280 : "auto";
        this.displayChart = true;
        this.styleConfig.bar.total.disabled = true;
      } else {
        this.summaryTableHeight = this.isForAdmin ? null : "auto";
        this.displayChart = false;
        this.styleConfig.bar.total.disabled = true;
      }
      this.currentReport.records[this.recordType] = [];
      this.query.recordType = recordType;
      this.query.reportId = reportId;
      if (studentId) {
        this.query.studentId = studentId;
      }
      this.loadData();
    },
    async loadData() {
      let url = "";
      if (this.isForAdmin) {
        url = `${this.controllerUrl}/getWeeklyReportAndRecords/${this.recordType}/${this.query.reportId}`;
      } else {
        url = `${this.controllerUrl}/getWeeklyReportAndRecordsForCurrentStudent/${this.recordType}/${this.query.reportId}`;
      }
      let res = await getData(url);
      if (
        res.result &&
        res.result.records &&
        res.result.records[this.recordType] &&
        res.code === "200"
      ) {
        console.log("WeeklyReportChart.loadData", res.result);
        // let recordList = res.result.records[this.recordType];
        // if (this.isForAdmin) {
        //   recordList = recordList.filter((e) => e.total > 0);
        //   res.result.records[this.recordType] = recordList;
        // }
        this.currentReport = res.result;
        if (!this.displayChart) return;
        let quizTotalRawScore = 0;
        for (let i = 0; i < 10; i++) {
          quizTotalRawScore += this.currentReport[`quizW${i + 1}RawScore`];
        }
        this.currentReport[`quizTotalRawScore`] = quizTotalRawScore;
      }

      this.startAndStepsSettingList = [];
      let lastAvailableRecordType = "w1";
      for (let recordType in this.styleConfig.bar) {
        let startAndStepsSetting = {};
        let start = 0;
        let end =
          this.currentReport[
            `quiz${this.$formatter.toUpperFirstChar(recordType)}RawScore`
          ] || 0;
        if (this.styleConfig.bar[recordType].disabled) continue;
        if (start === end) {
          this.styleConfig.bar[recordType].disabled = true;
          continue;
        }

        let steps = Math.ceil(parseInt(end) / 10);

        startAndStepsSetting["column"] = recordType;
        startAndStepsSetting["start"] = start;
        startAndStepsSetting["end"] = end;
        startAndStepsSetting["steps"] = steps;
        startAndStepsSetting["styleConfig"] = this.styleConfig.bar[recordType];
        startAndStepsSetting["disabled"] = this.styleConfig.bar[recordType].disabled;

        this.startAndStepsSettingList.push(startAndStepsSetting);

        if (startAndStepsSetting["disabled"] === false) {
          lastAvailableRecordType = recordType;
        }
      }
      this.activeName = `${lastAvailableRecordType}Histogram_${this.recordType}_${this.reportId}`;

      let recordsDistributionDic = null;
      let recordsDistributionInRankDic = null;
      let summarizedDataDic = null;
      let topStudentsDic = null;
      if (this.isForAdmin) {
        url = `${this.controllerUrl}/getWeeklyReportSummary/${this.recordType}/${this.query.reportId}`;
      } else {
        url = `${this.controllerUrl}/getWeeklyReportSummaryForStudent/${this.recordType}/${this.query.reportId}`;
      }
      console.log("loadData", url, this.startAndStepsSettingList);

      res = await postData(url, this.startAndStepsSettingList);
      if (res.result && res.code === "200") {
        let retData = res.result;
        recordsDistributionDic = retData.recordsDistribution;
        recordsDistributionInRankDic = retData.recordsDistributionInRank;
        summarizedDataDic = retData.summarizedData;
        topStudentsDic = retData.topStudents;

        this.weeklyReportSummary = retData;
      }

      if (!this.isForAdmin) {
        this.handleCurrentChange(this.currentReport.records[this.recordType][0]);
      }

      this.chartList = [];
      for (let i = 0; i < this.startAndStepsSettingList.length; i++) {
        let startAndStepsSetting = this.startAndStepsSettingList[i];
        if (startAndStepsSetting.disabled) continue;
        let parentNode = document.getElementById(
          `pane-${startAndStepsSetting.column}Histogram_${this.recordType}_${this.reportId}`
        );
        parentNode.innerHTML = "";

        this.renderBarChart(
          parentNode,
          recordsDistributionDic,
          recordsDistributionInRankDic,
          startAndStepsSetting,
          summarizedDataDic,
          topStudentsDic
        );
      }

      console.log(
        "loadData",
        this.currentReport,
        recordsDistributionDic,
        recordsDistributionDic,
        recordsDistributionInRankDic,
        summarizedDataDic,
        topStudentsDic
      );
    },
    renderBarChart(
      parentNode,
      recordsDistributionDic,
      recordsDistributionInRankDic,
      startAndStepsSetting,
      summarizedDataDic,
      topStudentsDic
    ) {
      const totalLegendLength = 12;
      let recordType = startAndStepsSetting.column;
      let start = startAndStepsSetting.start;
      let end = startAndStepsSetting.end;
      let steps = startAndStepsSetting.steps;
      let dataList = [];
      let labelList = [];
      let recordList = [];
      let summaryData = [];
      let topStudents = [];
      let camelName = this.$formatter.toUpperFirstChar(recordType);

      let segmentCount = Math.ceil((end - start) / steps);
      for (let t = 0; t <= segmentCount; t++) {
        let label = t * steps + start;
        if (label > 0) labelList.push(label);
      }

      for (let type in this.styleConfig.bar) {
        if (recordType !== type) continue;
        let maxVal = 0;
        let barStyleConfig = this.styleConfig.bar[type];
        if (barStyleConfig.disabled) continue;
        console.log("renderBarChart", this.chartContext.chartData);
        this.chartContext.chartData[recordType].datasets = [];
        let percentile = barStyleConfig.percentile;
        let valueFieldName = percentile ? `${recordType}Rank` : recordType;
        recordList = percentile
          ? recordsDistributionInRankDic[recordType]
          : recordsDistributionDic[recordType];
        summaryData = summarizedDataDic[recordType];
        topStudents = topStudentsDic[recordType];
        for (let i = 0; i < labelList.length; i++) {
          let label = labelList[i];
          let record = recordList.find((e) => e.name === `${label}`);
          if (record) {
            dataList.push(record.value);
            if (maxVal < record.value) maxVal = record.value;
          } else {
            dataList.push(0);
          }
        }
        // labelList = labelList.reverse();
        // dataList = dataList.reverse();

        this.chartContext.chartData[recordType].labels = labelList;
        let dataset = {
          label: `${camelName} Frequency`.padEnd(totalLegendLength + 9, " "),
          data: dataList,
          backgroundColor: barStyleConfig.bgColor,
          borderWidth: {
            top: 2,
            right: 1,
            bottom: 0,
            left: 1,
          },
          borderColor: "rgba(64, 158, 255, 0.3)",
          borderSkipped: "false",
          othis: this,
          recordType: recordType,
        };
        this.chartContext.chartData[recordType].datasets.push(dataset);

        if (recordType === "total") {
          let datasetForMyMedianLine = {
            type: "line",
            name: "medianScoreLine",
            label: "Median",
            data: [],
            backgroundColor: this.styleConfig.line.median.bgColor,
            borderWidth: 0,
          };
          this.chartContext.chartData[recordType].datasets.push(datasetForMyMedianLine);

          // let percentile85Data = summaryData.find(
          //   (item) => item.name.toLowerCase() === "percentile_85"
          // );
          let datasetForMyPercentile85Line = {
            type: "line",
            name: "percentile85ScoreLine",
            label: "85th-Percentile",
            data: [],
            backgroundColor: this.styleConfig.line.percentile_85.bgColor,
            borderWidth: 0,
          };
          this.chartContext.chartData[recordType].datasets.push(
            datasetForMyPercentile85Line
          );

          // let percentile95Data = summaryData.find(
          //   (item) => item.name.toLowerCase() === "percentile_95"
          // );
          let datasetForMyPercentile95Line = {
            type: "line",
            name: "percentile95ScoreLine",
            label: "95th-Percentile",
            data: [],
            backgroundColor: this.styleConfig.line.percentile_95.bgColor,
            borderWidth: 0,
          };
          this.chartContext.chartData[recordType].datasets.push(
            datasetForMyPercentile95Line
          );
        }

        // draw student's own's score line
        let scoreTitle = "";
        if (this.isForAdmin) {
          if (this.currentStudent && this.currentStudent.studentName) {
            let scoreValue = this.currentStudent[valueFieldName];
            scoreTitle = `${this.currentStudent.studentName}'s Percentile(${scoreValue})`;
          }
        } else {
          let scoreValue = this.currentStudent[valueFieldName];
          scoreTitle = `My Percentile(${scoreValue})`;
        }
        let datasetForMyScoreLine = {
          type: "line",
          name: "personalScoreLine",
          label: scoreTitle,
          data: [],
          backgroundColor: this.styleConfig.line.grade.bgColor,
          borderWidth: 0,
        };
        this.chartContext.chartData[recordType].datasets.push(datasetForMyScoreLine);
        // end of drawing student's owen score line

        let ctx = document.createElement("canvas");
        let div = document.createElement("div");
        div.appendChild(ctx);
        parentNode.appendChild(div);

        let opt = this.clone(this.chartContext.options);
        opt.plugins.title.text = `${camelName} Histogram`;
        opt.scales.y.max = maxVal + maxVal * 0.5;
        opt.plugins.legend.onClick = function (evt, item) {
          Chart.defaults.plugins.legend.onClick.call(this, evt, item, this);
          if (evt.chart && evt.chart.chartState) {
            if (item.text.indexOf(" Percentile(") > 0) {
              evt.chart.chartState.additionalData.hiddenMyScore = item.hidden;
            } else if (item.text.indexOf("Median") >= 0) {
              evt.chart.chartState.additionalData.hiddenMedian = item.hidden;
            } else if (item.text.indexOf("85th-Percentile") >= 0) {
              evt.chart.chartState.additionalData.hiddenPercentile85 = item.hidden;
            } else if (item.text.indexOf("95th-Percentile") >= 0) {
              evt.chart.chartState.additionalData.hiddenPercentile95 = item.hidden;
            }
          }
        };
        let chart = new Chart(ctx, {
          plugins: [
            {
              beforeDraw: (chart) => {
                if (!chart.chartState) return;
                // if (recordType !== "total") return;
                let chartState = chart.chartState;
                let scoreLineDatabaseIndex = 4;
                let _this = chartState.othis;
                let percentile = _this.styleConfig.bar[recordType].percentile;
                let capital = percentile ? "Percentile" : "Score";
                let valueFieldName = percentile ? `${recordType}Rank` : recordType;
                if (recordType !== "total") {
                  scoreLineDatabaseIndex = 1;
                }
                if (_this.currentStudent && _this.currentStudent.studentName) {
                  let scoreValue = _this.currentStudent[valueFieldName];
                  let scoreTitle = _this.isForAdmin
                    ? `${_this.currentStudent.studentName}'s ${capital}(${scoreValue})`
                    : `My ${capital}(${scoreValue})`;
                  chart.legend.legendItems[scoreLineDatabaseIndex].fillStyle =
                    _this.styleConfig.line.grade.bgColor;
                  chart.legend.legendItems[scoreLineDatabaseIndex].text = scoreTitle;
                } else {
                  chart.legend.legendItems[scoreLineDatabaseIndex].text = "";
                  chart.legend.legendItems[scoreLineDatabaseIndex].fillStyle =
                    _this.styleConfig.line.grade.bgColorForTransparency;
                }
              },
              afterDraw: function (chart) {
                let styleObj = null;
                if (!chart.chartState) return;
                // if (recordType !== "total") return;
                let chartState = chart.chartState;
                let _this = chartState.othis;
                let dataList = chartState.summaryData;
                let percentile = _this.styleConfig.bar[recordType].percentile;
                let capital = percentile ? "Percentile" : "Score";
                let valueFieldName = percentile ? `${recordType}Rank` : recordType;
                // let averageData = dataList.find(
                //   (item) => item.name.toLowerCase() === "average"
                // );
                // if (averageData) {
                //   styleObj = _this.styleConfig.averageLine;

                //   _this.drawVerticalLine(
                //     chart,
                //     averageData,
                //     styleObj,
                //     recordType,
                //     count,
                //   );
                // }
                if (recordType === "total") {
                  if (!chartState.additionalData.hiddenMedian) {
                    let medianData = dataList.find(
                      (item) => item.name.toLowerCase() === "median"
                    );
                    if (medianData) {
                      styleObj = _this.styleConfig.line.median;
                      _this.drawVerticalLine(chart, medianData, styleObj, recordType, 40);
                    }
                  }
                  if (!chartState.additionalData.hiddenPercentile85) {
                    let percentile85Data = dataList.find(
                      (item) => item.name.toLowerCase() === "percentile_85"
                    );
                    if (percentile85Data) {
                      styleObj = _this.styleConfig.line.percentile_85;
                      _this.drawVerticalLine(
                        chart,
                        percentile85Data,
                        styleObj,
                        recordType,
                        40
                      );
                    }
                  }
                  if (!chartState.additionalData.hiddenPercentile95) {
                    let percentile95Data = dataList.find(
                      (item) => item.name.toLowerCase() === "percentile_95"
                    );
                    if (percentile95Data) {
                      styleObj = _this.styleConfig.line.percentile_95;
                      _this.drawVerticalLine(
                        chart,
                        percentile95Data,
                        styleObj,
                        recordType,
                        40
                      );
                    }
                  }
                }

                styleObj = _this.styleConfig.line.ranking;
                _this.drawTopStudentsText(chart, chartState.topStudents, styleObj);

                if (
                  _this.currentStudent &&
                  _this.currentStudent.studentName &&
                  !chartState.additionalData.hiddenMyScore
                ) {
                  let scoreValue = _this.currentStudent[valueFieldName];
                  let scoreTitle = _this.isForAdmin
                    ? `${_this.currentStudent.studentName}'s ${capital}(${scoreValue})`
                    : `My ${capital}(${scoreValue})`;

                  styleObj = _this.styleConfig.line.grade;
                  _this.drawVerticalLine(
                    chart,
                    {
                      name: scoreTitle,
                      value: scoreValue,
                    },
                    styleObj,
                    recordType,
                    recordType === "total" ? 40 : 0
                  );
                }
              },
            },
            {
              beforeInit(chart) {
                const originalFit = chart.legend.fit;
                chart.legend.fit = function fit() {
                  originalFit.bind(chart.legend)();
                  this.height += 30;
                };
              },
            },
          ],
          type: "bar",
          data: this.chartContext.chartData[recordType],
          options: opt,
        });
        chart["chartState"] = {
          othis: this,
          recordType: recordType,
          labelList: labelList,
          dataList: dataList,
          summaryData: summaryData,
          topStudents: topStudents,
          additionalData: {
            hiddenMyScore: false,
            hiddenMedian: false,
            hiddenPercentile85: false,
            hiddenPercentile95: false,
          },
        };

        this.chartList.push(chart);
      }
    },
    drawVerticalLine(chart, gradeData, styleObj, recordType, threshold) {
      if (!chart.chartState) return;
      let chartState = chart.chartState;
      let _this = chartState.othis;
      let index = _this.getIndexValue(chartState.labelList, gradeData.value, threshold);
      if (index < 0) return;
      let xaxis = chart.scales["x"];
      let yaxis = chart.scales["y"];
      if (gradeData.value < threshold) {
        // let x1Pos = xaxis.getPixelForValue(0, undefined);
        // let x2Pos = xaxis.getPixelForValue(1, undefined);
        index = index / 4;
      }
      let xPos = xaxis.getPixelForValue(index, undefined);

      let ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      if (styleObj.isDashLine) {
        ctx.setLineDash(styleObj.dashPattern);
      }
      ctx.moveTo(xPos, yaxis.bottom);
      ctx.strokeStyle = styleObj.bgColor;
      ctx.lineWidth = styleObj.lineWidth;
      ctx.lineTo(xPos, yaxis.top);
      ctx.stroke();
      ctx.restore();

      if (styleObj.drawText) {
        ctx.font = styleObj.font;
        ctx.fillStyle = styleObj.foreColor;
        let txt = `${gradeData.name}: ${gradeData.value}`;
        let measure = ctx.measureText(txt);
        ctx.fillText(txt, xPos - measure.width / 2, yaxis.top - 10);
      }
    },
    drawTopStudentsText(chart, topStudents, styleObj) {
      if (!chart.chartState) return;
      let chartState = chart.chartState;
      let _this = chartState.othis;
      let xaxis = chart.scales["x"];
      let yaxis = chart.scales["y"];
      let ctx = chart.ctx;
      let recordType = chartState.recordType;
      let rightPos = xaxis.right + 12;
      let paddingTop = 12;
      let lineHeight = 18;
      let i = 0;
      let percentile = this.styleConfig.bar[recordType].percentile;
      let valueFieldName = percentile ? `${recordType}Rank` : recordType;
      let drawText = "";

      ctx.font = styleObj.font;
      ctx.fillStyle = styleObj.foreColor;
      ctx.textAlign = styleObj.textAlign;

      if (percentile) {
        ctx.fillText("Top Percentile", rightPos, yaxis.top + paddingTop);
      } else {
        ctx.fillText("Top Score", rightPos, yaxis.top + paddingTop);
      }

      for (; i < topStudents.length; i++) {
        let scoreValue = topStudents[i][valueFieldName];
        let studentName = this.trimStudentNameForTopStudents(topStudents[i].studentName);
        drawText = `${i + 1}. ${studentName}: ${scoreValue}`;
        ctx.fillStyle = styleObj[`foreColor${i + 1}`];
        ctx.fillText(drawText, rightPos, yaxis.top + paddingTop + lineHeight * (i + 1));
      }

      ctx.fillStyle = styleObj.foreColor;
      if (_this.currentStudent) {
        let recordList = this.currentReport.records[this.recordType] || [];
        let studentRecord = recordList.find(
          (e) => e.studentId === _this.currentStudent.studentId
        );
        if (_this.isForAdmin) {
          if (studentRecord) {
            let studentName = this.trimStudentNameForTopStudents(
              studentRecord.studentName
            );
            if (percentile) {
              drawText = `${studentName}: ${studentRecord[`${recordType}Rank`]}`;
            } else {
              drawText = `${studentName}: ${studentRecord[recordType]}`;
            }

            ctx.fillText(
              drawText,
              rightPos,
              yaxis.top + paddingTop + lineHeight * (i + 1)
            );
          }
        } else {
          console.log("studentRecord", studentRecord);
          if (percentile) {
            drawText = `My Percentile: ${studentRecord[`${recordType}Rank`]}`;
          } else {
            drawText = `My Score: ${studentRecord[recordType]}`;
          }
          ctx.fillText(drawText, rightPos, yaxis.top + paddingTop + lineHeight * (i + 1));
        }
      }
    },
    getIndexValue(labelList, val, threshold) {
      if (val > labelList[labelList.length - 1] || val < 0) return -1;
      const startGrade = threshold;
      if (val < startGrade) {
        return (val / startGrade) * 0.5;
      }
      for (let i = 0; i < labelList.length; i++) {
        if (labelList[i] === val) {
          return i + 0.5;
        } else if (val > labelList[i] && val < labelList[i + 1]) {
          let valRight = labelList[i + 1];
          let valLeft = labelList[i];

          return i + 0.5 + ((val - valLeft) / (valRight - valLeft)) * 1.0;
        }
      }
    },
    handleCurrentChange(row) {
      if (this.currentStudent === row) return;
      this.currentStudent = row;
      this.chartList.forEach((e) => e.render());
    },
    clone(target) {
      if (typeof target === "object") {
        let cloneTarget = Array.isArray(target) ? [] : {};
        for (const key in target) {
          cloneTarget[key] = this.clone(target[key]);
        }
        return cloneTarget;
      } else {
        return target;
      }
    },
    filterRecordList() {
      let _this = this;
      let recordList = this.currentReport.records[this.recordType] || [];
      recordList = recordList.filter((e) => {
        return (
          ((this.filterQuery.studentId || "").length === 0 ||
            e.studentId.includes(this.filterQuery.studentId)) &&
          ((this.filterQuery.studentName || "").length === 0 ||
            e.studentName
              .toLowerCase()
              .includes(this.filterQuery.studentName.toLowerCase()))
        );
      });

      recordList.sort((x, y) => {
        let sortBy =
          _this.query.orderBy.substring(0, 1).toLowerCase() +
          _this.query.orderBy.substring(1);
        let ret = x[sortBy] > y[sortBy] ? 1 : -1;
        return _this.query.orderDirection == "ASC" ? ret : -1 * ret;
      });

      return recordList;
    },
    specifyTableRowClassName({ row }) {
      // let topStudents = this.weeklyReportSummary.topStudents;
      // let list = topStudents.total;
      let list = [];
      let index = -1;
      if ((index = list.findIndex((e) => e.studentId === row.studentId)) >= 0) {
        return `top${index + 1}`;
      } else {
        return "";
      }
    },
    initializeLineColor(who, settingName) {
      let bgColor = this.$appSetting.getGenericValue(
        settingName,
        this.styleConfig.line[who].bgColor
      );
      let borderColor = bgColor;
      let arr = bgColor.split(",");
      if (arr.length === 4) {
        arr[arr.length - 1] = "0.8)";
        borderColor = arr.join(", ");
      }

      this.styleConfig.line[who].bgColor = bgColor;
      this.styleConfig.line[who].foreColor = borderColor;
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "ASC" : "DESC";
        let recordList = this.currentReport.records[this.recordType];
        if (recordList) {
          recordList.sort((x, y) => {
            return y.classRoom.activeStatus - x.classRoom.activeStatus;
          });
        }
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
    },
    getSummaries(columns, data) {
      console.log("getSummaries", columns, data);
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Avg/Total";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => Number.isNaN(value))) {
          sums[index] = `$ ${values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!Number.isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0)}`;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    trimStudentNameForTopStudents(studentName) {
      const maxStudentNameLength = 14;
      if (studentName && studentName.length > maxStudentNameLength) {
        studentName = studentName.substring(0, maxStudentNameLength - 1);
      }
      return studentName;
    },
    getPointsIssuedDescription(row) {
      row.points = Math.round(row.total * this.weeklyHomeworkPointsPerScore);
      if (row.pointsIssued) {
        if (this.isForAdmin) {
          if (row.points > 0) {
            return `${row.studentName} received ${row.points} points.`;
          } else {
            return `${row.studentName} got deducted ${row.points} points.`;
          }
        } else {
          if (row.points > 0) {
            return `You received ${row.points} points.`;
          } else {
            return `You got deducted ${row.points} points.`;
          }
        }
      } else {
        if (this.isForAdmin) {
          if (row.points > 0) {
            return `${row.studentName} will receive ${row.points} points next term.`;
          } else {
            return `${row.studentName} will get deducted ${row.points} points next term.`;
          }
        } else {
          if (row.points > 0) {
            return `You will receive ${row.points} points next term.`;
          } else {
            return `You will get deducted ${row.points} points next term.`;
          }
        }
      }
    },
    getPointsIssuedColor(row) {
      if (row.pointsIssued && row.pointsIssued === 1) {
        if (row.points > 0) {
          return "#000000";
        } else {
          return "#FF0000";
        }
      }
      if (row.points > 0) {
        return "#0000FF";
      } else {
        return "#FF0000";
      }
    },
  },
};
</script>
<style>
.el-table .top1 {
  background: inherit;
}
.el-table .top2 {
  background: inherit;
}
.el-table .top3 {
  background: inherit;
}
.dtable {
  margin-top: 10px;
  display: block;
  min-width: 100%;
  height: 100%;
  word-wrap: break-word;
  display: table;
}
table.dtable tr {
  min-width: 100%;
}
table.dtable tr td {
  width: 50%;
  height: 100%;
  vertical-align: top;
  word-wrap: break-word;
}
</style>
